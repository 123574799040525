var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.add}},[_vm._v(_vm._s('创建体验券'))])],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"name","label":"体验券名称"}}),_c('el-table-column',{attrs:{"prop":"vipDays","label":"天数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.vipDays)+"天 ")]}}])}),_c('el-table-column',{attrs:{"prop":"vipCardName","label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.vipCardName)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"expireDays","label":"有效期"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.expireDays)+"天 ")]}}])}),_c('el-table-column',{attrs:{"prop":"expireDays","label":"使用/领取/总数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"toReceive",on:{"click":function($event){return _vm.toReceive(row)}}},[_vm._v(" "+_vm._s(((row.useTotal) + "/" + (row.getTotal) + "/" + (row.number)))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"sunCode","label":"二维码"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"80px"},attrs:{"src":_vm.baseurl + _vm.yu + row.sunCode,"preview-src-list":[_vm.baseurl + _vm.yu + row.sunCode]}})]}}])}),_c('el-table-column',{attrs:{"label":"备注"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('remark',{attrs:{"row":row,"permissions":true,"val":{
                            url: '/admin/InvitationCoupon/editRemark',
                            pw: 'user_list_remark',
                        },"getlist":_vm.getlist}})]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(_vm._s(row.status ? '停止' : '启用'))])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [_c('el-button',{staticStyle:{"color":"skyblue"},attrs:{"type":"text","icon":"el-icon-edit","size":"mini"},on:{"click":function($event){return _vm.editor(row)}}},[_vm._v("编辑")]),_c('el-button',{staticStyle:{"color":"pink"},attrs:{"type":"text","icon":"el-icon-s-promotion","size":"mini"},on:{"click":function($event){return _vm.shelves(row)}}},[_vm._v(_vm._s(row.status == 0 ? '停用' : '启用'))])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.search.page,"background":"","page-sizes":[3, 5, 10, 15],"page-size":_vm.search.pageSize,"layout":"total, prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1),_c('Drawers',{ref:"drawer",on:{"getlist":_vm.onList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }